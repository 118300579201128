import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

/* UTILS */
import RestActions from 'utils/rest/actions';
import serialize from 'utils/rest/serialize';
import { contactablesSortAdapter } from 'utils/adapters';
import naturalCompare from 'utils/naturalSort';
import { parseFilters } from 'utils/helpers';

/* PATH */
import {
  tasksPath,
  taskPath,
  formTypesPath,
  getCompleteFormPath,
  getUpdateClaimPath,
  getDeleteFormAnswerPath,
  getPeoplePath,
  getOrganizationPath,
  searchContactablesPath,
  answersPath,
  getAnswerPath,
  updateTaskAnswersPath,
} from 'utils/paths';

/* TYPES */
import {
  SET_TASK_GROUPS,
  COMPLETE_TASK_START,
  COMPLETE_TASK_FINISH,
  FETCH_FORM_TYPES_START,
  FETCH_FORM_TYPES_FINISH,
  SET_FORM_ANSWER,
  DELETE_EMPTY_ANSWERS,
  CLEAR_TASK_ANSWERS,
  CLEAR_TASK_DATA,
  SET_SUGGESTION_PEOPLE_ORGANIZATIONS,
  SET_SUGGESTION_PERSON_OR_ORGANIZATION,
  CREATE_TASK_ANSWER__START,
  CREATE_TASK_ANSWER__FINISH,
} from './types';
import { tasksPathFirst } from '../utils/paths';
import { ROLES } from 'utils/constants';

const restTaskActions = new RestActions('task');

const {
  fetchAllStart,
  fetchAllFinished,
  fetchStart,
  fetchFinished,
  deleteStart,
  deleteFinished,
  updateStart,
  updateFinished,
  createStart,
  createFinished,
} = restTaskActions;

/* ACTIONS */
export const fetchTasks =
  (params = {}, filters = []) =>
  (dispatch) => {
    dispatch(fetchAllStart());
    const processedParams = { ...params };
    if (params.search?.value) {
      processedParams.page = params.searchPage || 1;
    }

    return axios
      .get(
        `${tasksPath}${serialize(
          processedParams,
          true
        )}&expose_claim=false&status_not_eq=1&form_types_roles_name_cont=agent&archived_eq=false${parseFilters(
          filters
        )}`
      )
      .then(
        ({ data }) => {
          const formattedTasks = data.forms.map((task) => ({
            ...task,
            userFullName: task.agent
              ? `${task.agent.first_name} ${task.agent.last_name}`
              : task.claim.agent
              ? `${task.claim.agent.first_name} ${task.claim.agent.last_name}`
              : '',
            customerFullName: `${task.user.first_name || ''} ${
              task.user.last_name || ''
            }`,
            type: task.form_type.name.replace('_', ' '),
            claimType: task.claim.claim_type.name,
            status: task.status ? task.status.replace('_', ' ') : '',
            claimRefId: task.claim.ref_id,
          }));

          formattedTasks.sort(function (a, b) {
            return new Date(a.created_at) - new Date(b.created_at);
          });

          const res = {
            ...data,
            result: formattedTasks,
          };

          dispatch(fetchAllFinished(camelizeKeys(res)));
          return res;
        },
        (error) => {
          dispatch(fetchAllFinished(error));
        }
      );
  };

const makeFetchTasksQuery = ({ ids, params, filters }) => {
  const queryIds = ids.map((agentId) => `&agent_id_in[]=${agentId}`);
  return `${serialize(params, true)}${queryIds.join(
    ''
  )}&expose_claim=false&status_not_eq=1&form_types_roles_name_cont=agent&archived_eq=false${parseFilters(
    filters
  )}`;
};

export const fetchAgentTasks =
  ({ agentIds = [], query = {}, filters = [], isAllAgentsFilter = false }) =>
  (dispatch) => {
    dispatch(fetchAllStart());

    const processedParams = { ...query };
    if (query.search?.value) {
      processedParams.page = query.searchPage || 1;
    }

    const processedFilters = isAllAgentsFilter
      ? filters.filter((f) => f.variable?.toLowerCase() !== 'agent_id_in[]')
      : filters;

    const containsAgentFilter = filters.find(
      (f) => f.variable?.toLowerCase() === 'agent_id_in[]'
    );

    const queryString = makeFetchTasksQuery({
      params: processedParams,
      filters: processedFilters,
      ids: isAllAgentsFilter || containsAgentFilter ? [] : agentIds,
    });

    return axios.get(`${tasksPath}${queryString}`).then(
      ({ data }) => {
        const formattedTasks = data.forms.map((task) => ({
          ...task,
          userFullName: task.agent
            ? `${task.agent.first_name} ${task.agent.last_name}`
            : task.claim.agent
            ? `${task.claim.agent.first_name} ${task.claim.agent.last_name}`
            : '',
          customerFullName: `${task.user.first_name || ''} ${
            task.user.last_name || ''
          }`,
          type: task.form_type.name.replace('_', ' '),
          claimType: task.claim.claim_type.name,
          status: task.status ? task.status.replace('_', ' ') : '',
          claimRefId: task.claim.ref_id,
        }));

        formattedTasks.sort(function (a, b) {
          return new Date(a.created_at) - new Date(b.created_at);
        });

        const res = {
          ...data,
          result: formattedTasks,
        };

        dispatch(fetchAllFinished(camelizeKeys(res)));
      },
      (error) => {
        dispatch(fetchAllFinished(error));
      }
    );
  };

export const fetchTask = (id, cb) => (dispatch) => {
  dispatch(fetchStart());
  return axios
    .get(taskPath(id), { params: { skip_claim_questions: true } })
    .then(
      ({ data }) => {
        //Format questions witch have sub_questions

        const formattedQuestions = data.form.questions.reduce((acc, el) => {
          if (el.sub_questions.length) {
            for (let i = 0; i < el.sub_questions.length; i++) {
              acc.push(el.sub_questions[i]);
            }
          }

          acc.push(el);

          return acc;
        }, []);

        const formattedTask = {
          ...data.form,
          customerFullName: `${data.form.claim.customer.first_name || ''} ${
            data.form.claim.customer.last_name || ''
          }`,
          type: data.form.form_type
            ? data.form.form_type.name.replace('_', ' ')
            : '',
          status: data.form.status ? data.form.status.replace('_', ' ') : '',
          canBeCompleted: data.form.progress.status >= 1,
          customerRefId: data.form.claim.customer
            ? data.form.claim.customer.ref_id
            : '',
          questions: formattedQuestions,
        };
        dispatch(fetchFinished(camelizeKeys(formattedTask)));
        if (cb) cb(data);
        return camelizeKeys(formattedTask);
      },
      (error) => {
        dispatch(fetchFinished(error));
      }
    );
};

export const deleteTask = (id) => (dispatch) => {
  dispatch(deleteStart());
  return axios.delete(taskPath(id)).then(
    (result) => {
      dispatch(deleteFinished(result));
    },
    (error) => {
      dispatch(deleteFinished(error));
    }
  );
};

export const updateTask =
  (id, params = {}) =>
  (dispatch) => {
    dispatch(updateStart());
    return axios.put(taskPath(id), decamelizeKeys(params)).then(
      (result) => {
        dispatch(updateFinished(result));
      },
      (error) => {
        dispatch(updateFinished(error));
      }
    );
  };

export const createTask =
  (params = {}) =>
  (dispatch) => {
    dispatch(createStart());
    return axios.post(tasksPathFirst, decamelizeKeys(params)).then(
      ({ data }) => {
        const formattedTask = {
          ...data.result,
          customerFullName: `${data.result.claim.user.first_name || ''} ${
            data.result.claim.user.last_name || ''
          }`,
          type: data.result.form_type
            ? data.result.form_type.name.replace('_', ' ')
            : '',
          status: data.result.status
            ? data.result.status.replace('_', ' ')
            : '',
          canBeCompleted: data.result.progress.status >= 1,
          customerRefId: data.result.user ? data.result.user.ref_id : '',
        };
        dispatch(createFinished(camelizeKeys(formattedTask)));
      },
      (error) => {
        dispatch(createFinished(error));
      }
    );
  };

export const completeTask =
  (id, params = {}, cb) =>
  (dispatch) => {
    dispatch({ type: COMPLETE_TASK_START });
    return axios
      .put(
        getCompleteFormPath(id),
        decamelizeKeys(params, { split: /(?=[A-Z0-9])/ })
      )
      .then(
        ({ data }) => {
          dispatch({ type: COMPLETE_TASK_FINISH, payload: data.result });
          dispatch(fetchTask(data.result.id));
          if (cb) cb();
        },
        (error) => {
          dispatch({ type: COMPLETE_TASK_FINISH, payload: error });
        }
      );
  };

const defineTaskType = (taskType) => {
  switch (taskType) {
    case 0:
      return ROLES.agent;
    case 1:
      return ROLES.customer;
    case 2:
      return ROLES.customer;
    case 3:
      return ROLES.customer;
    case 4:
      return ROLES.admin;
    case 5:
      return ROLES.supervisor;
    default:
      return ROLES.customer;
  }
};

export const fetchFormTypes =
  (params = {}, taskType = true, value = 0) =>
  (dispatch) => {
    dispatch({ type: FETCH_FORM_TYPES_START });
    return axios
      .get(
        `${formTypesPath}${serialize(
          params,
          true
        )}&roles_name_cont=${defineTaskType(value)}&status_eq=enabled`
      )
      .then(
        ({ data }) => {
          const formattedTypes = data.result.map((type) => ({
            ...type,
            name: type.name ? type.name.replace('_', ' ') : '',
          }));
          formattedTypes.sort(naturalCompare);

          dispatch({
            type: FETCH_FORM_TYPES_FINISH,
            payload: camelizeKeys(formattedTypes),
          });
        },
        (error) => {
          dispatch({ type: FETCH_FORM_TYPES_FINISH, payload: error });
        }
      );
  };

export const setTaskGroups = (payload) => (dispatch) => {
  dispatch({
    type: SET_TASK_GROUPS,
    payload,
  });
};

export const setFormAnswer = (answ) => (dispatch) => {
  dispatch({
    type: SET_FORM_ANSWER,
    payload: answ,
  });
};

export const updateFormAnswers = (id, params) => (dispatch) => {
  dispatch(updateStart());
  return axios.put(getUpdateClaimPath(id), decamelizeKeys(params)).then(
    ({ data }) => {
      dispatch(updateFinished(camelizeKeys(data.result)));
      return data.result;
    },
    (error) => {
      dispatch(updateFinished(error));
    }
  );
};

export const createTaskAnswer = (params) => (dispatch) => {
  dispatch({ type: CREATE_TASK_ANSWER__START });
  return axios.post(answersPath, decamelizeKeys(params)).then(
    ({ data }) => {
      const answer = data.answer ?? data.result;
      dispatch({
        type: CREATE_TASK_ANSWER__FINISH,
        payload: camelizeKeys(answer),
      });
      return answer;
    },
    (error) => {
      dispatch(updateFinished(error));
    }
  );
};

export const updateTaskAnswer = (answerId, params) => (dispatch) => {
  dispatch(updateStart());
  return axios.put(getAnswerPath(answerId), decamelizeKeys(params)).then(
    ({ data }) => {
      const answer = data.answer ?? data.result;
      dispatch(updateFinished(camelizeKeys(answer)));
      return answer;
    },
    (error) => {
      dispatch(updateFinished(error));
    }
  );
};

export const deleteTaskAnswer = (answerId) => (dispatch) => {
  dispatch(deleteStart());
  return axios.delete(getAnswerPath(answerId)).then(
    ({ data }) => {
      const answer = data.answer ?? data.result;
      dispatch(deleteFinished(camelizeKeys(answer)));
      return answer;
    },
    (error) => {
      dispatch(updateFinished(error));
    }
  );
};

export const updateTaskAnswers = (id, params) => (dispatch) => {
  dispatch(updateStart());
  return axios.patch(updateTaskAnswersPath(id), decamelizeKeys(params)).then(
    (response) => {
      const { data } = response;
      dispatch(updateFinished(camelizeKeys(data.result)));
    },
    (error) => {
      dispatch(updateFinished(error));
    }
  );
};

export const deleteEmptyAnswers = (formId, answers) => (dispatch) => {
  return axios
    .all(
      // eslint-disable-next-line
      answers.map((answ) => {
        if (answ.answerId) {
          return axios.delete(getDeleteFormAnswerPath(formId, answ.answerId));
        }
      })
    )
    .then(
      axios.spread(
        (...res) => {
          dispatch({
            type: DELETE_EMPTY_ANSWERS,
            payload: res,
          });
        },
        (error) => {
          dispatch({
            type: DELETE_EMPTY_ANSWERS,
            payload: error,
          });
        }
      )
    );
};

export const deleteEmptyAnswer = (formId, answerId) => (dispatch) => {
  if (answerId) {
    return axios.delete(getDeleteFormAnswerPath(formId, answerId)).then(
      ({ data }) => {
        dispatch({
          type: DELETE_EMPTY_ANSWERS,
          payload: data,
        });
      },
      (error) => {
        dispatch({
          type: DELETE_EMPTY_ANSWERS,
          payload: error,
        });
      }
    );
  }
};

export const clearTaskAnswers = () => (dispatch) => {
  dispatch({
    type: CLEAR_TASK_ANSWERS,
  });
};

export const clearTaskData = () => (dispatch) => {
  dispatch({
    type: CLEAR_TASK_DATA,
  });
};

export const fetchPersonsAndOrganizations = (options) => (dispatch) => {
  return axios.post(`${searchContactablesPath}`, decamelizeKeys(options)).then(
    ({ data }) => {
      dispatch(setSuggestion(contactablesSortAdapter(camelizeKeys(data))));
    },
    (error) => {
      dispatch(
        fetchAllFinished({
          hasError: true,
          errorText: error.response.data.error,
        })
      );
    }
  );
};

const setSuggestion = (data) => (dispatch) => {
  dispatch({
    type: SET_SUGGESTION_PEOPLE_ORGANIZATIONS,
    payload: data,
  });
};

const setSuggestionPersonOrOrganization = (data) => (dispatch) => {
  dispatch({
    type: SET_SUGGESTION_PERSON_OR_ORGANIZATION,
    payload: data,
  });
};

export const fetchPersonForSuggestion = (id) => (dispatch) => {
  return axios.get(getPeoplePath(id)).then(
    ({ data }) => {
      dispatch(setSuggestionPersonOrOrganization(camelizeKeys(data.person)));
      return data;
    },
    (error) => {
      dispatch(fetchFinished(error));
      return false;
    }
  );
};

export const fetchOrganizationForSuggestion = (id) => (dispatch) => {
  return axios.get(getOrganizationPath(id)).then(
    ({ data }) => {
      dispatch(
        setSuggestionPersonOrOrganization(camelizeKeys(data.organization))
      );
      return data;
    },
    (error) => {
      dispatch(fetchFinished(error));
      return false;
    }
  );
};
