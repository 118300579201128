export const processErrors = ({ validationError, propError }) => {
  if (!validationError && (!propError || propError?.length === 0)) return null;
  let result = [];
  if (validationError) {
    result.push(validationError);
  }

  if (propError?.length > 0) {
    result = [...result, propError];
  }

  return result;
};
