import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ChangeablePreviewPopup from 'components/ChangeablePreviewPopup';
import InputErrors from 'components/InputErrors';

import Icons from 'common/Icons';

import { FILE_TYPES } from 'utils/constants';
import fileUploadingValidation from 'validation/fileUploadingValidation';
import './styles.scss';
import { processErrors } from 'components/helpers';

class MultipleFileLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputName: null,
      fileLoading: false,
      files: [],
      deleting: null,
      isModalOpen: false,
      selectedFile: null,
      validationError: null,
      propError: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { fileLoading, validationError, propError } = this.state;
    const { name, fieldErrors, document, errors } = this.props;
    const { previews } = document;
    const unifiedError = processErrors({
      validationError,
      propError,
    });

    if (
      previews.length > 0 &&
      fileLoading &&
      previews.find((prev) => prev.input === name && prev.files.length > 0)
    ) {
      this.completeLoading();
    }

    if (prevProps.task.fetchingOne && this.props.task.fetchedOne) {
      this.clear();
    }

    if (unifiedError?.length > 0 && fileLoading) {
      this.completeLoading();
    }

    if (
      (prevProps.errors?.length === 0 && errors?.length > 0) ||
      (prevProps.errors?.length > 0 &&
        errors?.length > 0 &&
        prevProps.errors !== errors)
    ) {
      this.setState({ propError: errors });
    }

    if (prevProps.errors?.length && !errors?.length) {
      this.setState({ propError: {} });
    }

    // Clear errors if there were fieldErrors before and now aren't
    if (
      prevProps &&
      this.props &&
      prevProps.fieldErrors &&
      fieldErrors &&
      Object.keys(prevProps.fieldErrors).length > 0 &&
      Object.keys(fieldErrors).length === 0
    ) {
      this.setState({ validationError: null });
    }
  }

  getFileData = (documents) => {
    const { name } = this.props;
    const { previews } = this.props.document;
    const currentPreview = previews.find((prev) => prev.multiple);
    const formattedFiles = documents.map(
      (doc) =>
        (doc = {
          // eslint-disable-line
          id: doc.id,
          url: typeof doc.url === 'object' ? doc.url.original : doc.url,
          name: doc.name || doc.description,
          loading: false,
        })
    );

    const files = currentPreview
      ? [...formattedFiles, ...currentPreview.files]
      : formattedFiles;

    const result = {
      input: name,
      files,
      name: '',
      multiple: true,
    };

    return result;
  };

  completeLoading = () => {
    this.setState({ fileLoading: false });
  };

  loadFile = (e) => {
    e.persist();

    const { validationError } = this.state;
    const { setFieldErrors, clearFieldError, onUploadDocumentClick } =
      this.props;
    const pictures = e.target.files;
    const inputName = e.target.name;
    const hasData = pictures && Object.keys(pictures).length;

    if (hasData) {
      let hasError;
      for (const picture in pictures) {
        if (Object.hasOwnProperty.call(pictures, picture)) {
          const element = pictures[picture];
          const pictureUploadingError = fileUploadingValidation(
            FILE_TYPES.image,
            element
          );

          if (pictureUploadingError) {
            hasError = pictureUploadingError;
            this.setState({
              validationError: pictureUploadingError,
            });

            setFieldErrors({ [inputName]: pictureUploadingError });

            return;
          }
        }
      }

      if (!hasError && validationError) {
        this.setState(
          {
            validationError: null,
          },
          () => {
            clearFieldError(inputName);
          }
        );
      }

      if (hasError) return;
    }

    const multiple = true;

    this.setState({
      inputName,
      fileLoading: true,
      files: pictures,
      propError: null,
    });
    onUploadDocumentClick(e, multiple, {
      fileType: FILE_TYPES.image,
    });
  };

  removeFile = async (e, inputName, fileId) => {
    e.persist();
    e.preventDefault();

    const { deleting } = this.state;
    const { withoutAutosave, removeSelectedDocument, name } = this.props;
    const multiple = true;

    if (deleting) return;

    if (!withoutAutosave) {
      this.setState({ deleting: fileId });
    }

    const questionId = inputName ? inputName : name;

    await removeSelectedDocument(questionId, fileId, multiple);
  };

  clear = () => {
    this.setState({ deleting: null });
  };

  switchModal = (file) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      selectedFile: file && file.url ? file : null,
    });
  };

  getValueFromTask = (incomingTask) => {
    let result = null;
    const { task, id } = this.props;
    const taskAnswers = incomingTask
      ? incomingTask?.data?.answers
      : task?.data?.answers;
    if (taskAnswers?.length) {
      const currentQuestion = taskAnswers.find(
        (answer) => Number(answer.questionId) === Number(id)
      );

      if (currentQuestion?.documents?.length)
        result = currentQuestion.documents;
    }

    return result;
  };

  render() {
    const {
      className,
      wrapperClass,
      name,
      label,
      disabled,
      style,
      document,
      value,
      newDesign,
    } = this.props;
    const { previews, hidden } = document;
    const { fileLoading, deleting, selectedFile, validationError, propError } =
      this.state;
    const listLength = 12;
    let questionAnswerDocuments = this.getValueFromTask();
    let loadedPreview;

    if (questionAnswerDocuments && questionAnswerDocuments?.length) {
      const filteredAnswers = questionAnswerDocuments.filter(
        (v) => !hidden.includes(v.id)
      );

      loadedPreview = this.getFileData(filteredAnswers);
    } else if (!questionAnswerDocuments && value && value.length > 0) {
      questionAnswerDocuments = value[0]?.documents;
      loadedPreview = this.getFileData(value);
    } else {
      loadedPreview = previews.find((prev) => prev.multiple);
    }

    const inputDisabledClass = disabled ? 'cursor-without-hand' : null;

    const newDesignStyles = newDesign ? 'new-design-field' : null;
    const newDesignLabel = newDesign
      ? 'new-design-multiple-file-upload-label'
      : null;

    const unifiedError = processErrors({
      validationError,
      propError,
    });

    return (
      <Fragment>
        <div
          className={`form-control-multiplefile-container ${newDesignStyles}`}
          style={style}
        >
          <span className={`form-control-file-label ${newDesignLabel}`}>
            {label}
          </span>
          <div className="file-loader-multiple flex-wrap">
            <ChangeablePreviewPopup
              isOpen={this.state.isModalOpen}
              onBackdropClick={this.switchModal}
              value={questionAnswerDocuments}
              // value={value}
              selectedFile={selectedFile}
              loadedPreview={loadedPreview}
            />
            {loadedPreview &&
              loadedPreview.files.length > 0 &&
              loadedPreview.files.map((file) => (
                <div
                  key={file.id}
                  className="upload-form multiple-file flex direction-column items-center justify-start"
                >
                  <div
                    className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center`}
                  >
                    {file && !file.loading && file.id !== deleting && (
                      <div className="btn-background">
                        <img
                          className="background-img"
                          src={file.url}
                          alt=""
                          onClick={() => this.switchModal(file)}
                        />
                        {!disabled && (
                          <button
                            className="background-remove"
                            onClick={(e) => this.removeFile(e, name, file.id)}
                          >
                            <Icons
                              iconName="close"
                              fill="#ff6081"
                              height={30}
                              width={30}
                            />
                          </button>
                        )}
                      </div>
                    )}
                    {(file.loading || file.id === deleting) && (
                      <label
                        htmlFor={name}
                        onChange={this.loadFile}
                        className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center`}
                      >
                        {
                          <div className="lds-spinner">
                            {Array.from(Array(listLength), (_, i) => (
                              <div key={i} />
                            ))}
                          </div>
                        }
                      </label>
                    )}
                  </div>
                  {file && !file.loading && file.id !== deleting && (
                    <span className="form-control-file-sublabel">
                      {file.name}
                    </span>
                  )}
                  {file.loading && (
                    <span className="form-control-file-sublabel">
                      Uploading...
                    </span>
                  )}
                  {file.id === deleting && (
                    <span className="form-control-file-sublabel">
                      Deleting...
                    </span>
                  )}
                </div>
              ))}

            {!fileLoading && (
              <div className="multiple-file">
                <label
                  htmlFor={name}
                  onChange={this.loadFile}
                  className={`${wrapperClass} form-control-file-wrapper flex justify-center items-center ${inputDisabledClass}`}
                >
                  <input
                    type="file"
                    id={name}
                    className={`form-control form-control-file ${className}`}
                    name={name}
                    disabled={disabled}
                    multiple
                  />
                  {!disabled && (
                    <Icons
                      iconName="plus"
                      fill="#ff6081"
                      height={40}
                      width={40}
                    />
                  )}
                </label>
              </div>
            )}
          </div>
          {unifiedError ? <InputErrors errors={unifiedError} /> : null}
        </div>
      </Fragment>
    );
  }
}

MultipleFileLoader.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  name: PropTypes.string,
  onUploadDocumentClick: PropTypes.func.isRequired,
  document: PropTypes.shape({
    previews: PropTypes.array,
    hidden: PropTypes.array,
  }),
  task: PropTypes.shape({
    fetchingOne: PropTypes.bool,
    fetchedOne: PropTypes.bool,
  }).isRequired,
  removeSelectedDocument: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.arrayOf(Object),
  disabled: PropTypes.bool,
  withoutAutosave: PropTypes.bool,
};

MultipleFileLoader.defaultProps = {
  className: '',
  wrapperClass: null,
  name: '',
  document: {},
  removeSelectedDocument: null,
  label: '',
  value: [],
  disabled: false,
  withoutAutosave: false,
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps, {})(MultipleFileLoader);
