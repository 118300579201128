import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import PDFViewer from 'components/PDFViewer';
import fileUploadingValidation from 'validation/fileUploadingValidation';
import { FILE_TYPES } from 'utils/constants';

import DocumentPlaceholder from './components/DocumentPlaceholder';
import MediaUploadEmpty from './components/MediaUploadEmpty';
import Spinner from './components/Spinner';
import InputErrors from 'components/InputErrors';
import { processErrors } from 'components/helpers';

const MediaUpload = ({
  file,
  label,
  browser,
  fileType,
  disabled,
  value,
  name,
  removeSelectedDocument,
  setFieldErrors,
  clearFieldError,
  onUploadDocumentClick,
  task,
  id,
  newDesign,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const [validationError, setValidationError] = useState(null);

  const getValueFromTask = () => {
    let result = null;
    const taskAnswers = task?.data?.answers;
    if (taskAnswers?.length) {
      const currentQuestion = taskAnswers.find(
        (answer) => Number(answer.questionId) === Number(id)
      );
      if (currentQuestion?.documents?.length)
        result = currentQuestion.documents;
    }

    return result;
  };

  let filename;
  let description;
  let uploadDocument;

  let questionAnswerDocument = getValueFromTask();

  if (
    questionAnswerDocument?.length &&
    questionAnswerDocument[0]?.documents?.length
  ) {
    uploadDocument = questionAnswerDocument[0]?.documents[0];
    filename = uploadDocument?.filename;
    description = uploadDocument?.description;
  }

  const handleClick = (e) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const getFileData = (document) => {
    const result = {
      input: name,
      fileId: document.id,
      file: document.url ? document.url.original : '',
      name: document.description ?? document.filename,
    };

    return result;
  };

  const loadFile = (e) => {
    e.persist();

    const pictures = e.target.files;
    const inputName = e.target.name;
    const hasData = pictures && Object.keys(pictures).length;

    if (hasData) {
      let hasError;
      for (const picture in pictures) {
        if (Object.hasOwnProperty.call(pictures, picture)) {
          const element = pictures[picture];
          const pictureUploadingError = fileUploadingValidation(
            FILE_TYPES.document,
            element
          );

          if (pictureUploadingError) {
            hasError = pictureUploadingError;
            setValidationError(pictureUploadingError);

            setFieldErrors({ [inputName]: pictureUploadingError });

            return;
          }
        }
      }

      if (!hasError && validationError) {
        setValidationError(null);
        clearFieldError(inputName);
      }

      if (hasError) return;
    }

    setFileLoading(true);

    onUploadDocumentClick(e, false, { fileType: FILE_TYPES.document });
  };

  const removeFile = async (e, loadedFile) => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();

    if (deleting) return;

    const documentId = uploadDocument?.id || loadedFile.fileId;

    if (name && documentId) {
      setDeleting(true);
      removeSelectedDocument(name, documentId);
    }
  };

  let loadedFile;

  const isMobile = browser?.windowSize[0] < 576;

  if (questionAnswerDocument?.length > 0 && !value?.length) {
    loadedFile = getFileData(questionAnswerDocument[0]);
  }
  if (value?.length > 0) {
    loadedFile = getFileData(value[0]);
  }

  useEffect(() => {
    if (fileLoading && loadedFile) setFileLoading(false);
    if (deleting && !loadedFile) setDeleting(false);

    const unifiedError = processErrors({
      validationError,
      propError: errors,
    });
    if (unifiedError?.length > 0 && fileLoading) setFileLoading(false);
  }, [fileLoading, loadedFile, deleting, errors, validationError]);

  const newDesignStyles = newDesign ? 'new-design-field' : null;
  const newDesignLabel = newDesign ? 'new-design-media-upload-label' : null;

  const processedFileType = fileType
    ? fileType
    : file
    ? file.name?.split('.')[1]
    : loadedFile?.name?.split('.')[1];

  const unifiedError = processErrors({
    validationError,
    propError: errors,
  });

  return (
    <div
      className={`form-control-file-container media-upload field-border-radius ${newDesignStyles}`}
    >
      {(fileLoading || deleting) && <Spinner />}
      <span className={`form-control-file-label ${newDesignLabel}`}>
        {label}
      </span>
      {!loadedFile && !fileLoading && !disabled && (
        <MediaUploadEmpty disabled={disabled} loadFile={loadFile} name={name} />
      )}
      {loadedFile && (
        <>
          <div className="media-upload-content">
            <DocumentPlaceholder
              onClick={handleClick}
              fileName={filename || description || loadedFile?.name}
              removeFile={(e) => {
                e.persist();
                e.preventDefault();
                removeFile(e, loadedFile);
              }}
              disabled={disabled || fileLoading || deleting}
            />
            {isOpen && (
              <PDFViewer
                url={file ?? loadedFile?.file}
                classWrap={`pdf-single col-xs-12 show`}
                classContent="mob-preview"
                navClass=""
                fileType={processedFileType}
                isMobile={isMobile}
                iFrameClass={`media-upload-content-preview`}
              />
            )}
          </div>
          <span className="form-control-file-sublabel media-upload-file-name">
            {filename || description || loadedFile?.name}
          </span>
        </>
      )}
      {unifiedError ? <InputErrors errors={unifiedError} /> : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  document: state.document,
  task: state.task,
});

export default connect(mapStateToProps)(MediaUpload);
