/* UTILS */
import restTypes from 'utils/rest/types';
import restStates from 'utils/rest/states';
import restInitState from 'utils/rest/initState';

/* TYPES */
import {
  CLEAR_PREVIEWS_LIST,
  SET_DOCUMENT_PREVIEW,
  REMOVE_DOCUMENT_PREVIEW,
  REMOVE_MULTIPLE_DOCUMENT_PREVIEW,
  HIDE_MULTIPLE_PREVIEW,
  HIDE_SINGLE_PREVIEW,
  SET_VIDEO_PREVIEW,
  REMOVE_VIDEO_PREVIEW,
} from 'actions/types';

const initialState = {
  previews: [],
  videoPreviews: [],
  hidden: [],
};

const {
  createStart,
  createFinished,
  deleteStart,
  deleteFinished,
  requestError,
} = restTypes('document');

const document = (
  state = { ...restInitState, ...initialState },
  action = {}
) => {
  switch (action.type) {
    case createStart:
      return {
        ...state,
        ...restStates.createStart,
      };

    case createFinished:
      return {
        ...state,
        ...restStates.createFinished,
        data: action.payload,
      };

    case deleteStart:
      return {
        ...state,
        ...restStates.deleteStart,
      };

    case deleteFinished:
      return {
        ...state,
        ...restStates.deleteFinished,
      };

    case requestError:
      return {
        ...state,
        ...restStates.createFinished,
        error: action.payload,
      };

    case CLEAR_PREVIEWS_LIST:
      return {
        ...state,
        previews: [],
        videoPreviews: [],
      };

    case HIDE_SINGLE_PREVIEW:
      return {
        ...state,
        hidden: [...state.hidden, action.payload.fileId],
        previews:
          state.previews.length > 1
            ? [
                state.previews.filter(
                  (item) => item.fileId !== action.payload.fileId
                ),
              ]
            : [],
      };

    case HIDE_MULTIPLE_PREVIEW:
      return {
        ...state,
        hidden: [...state.hidden, action.payload.fileId],
        previews: state.previews.find(
          (preview) => preview.input === action.payload.inputName
        )
          ? [
              ...state.previews,
              ...state.previews.find((preview) => {
                if (preview.input === action.payload.inputName) {
                  preview.files = preview.files.filter(
                    (
                      item // eslint-disable-line
                    ) => item.id !== action.payload.fileId
                  );
                }
                return preview;
              }),
            ]
          : state.previews,
      };

    case SET_DOCUMENT_PREVIEW:
      return {
        ...state,
        previews:
          state.previews.length > 0 &&
          state.previews.find(
            (preview) => preview.input === action.payload.input
          )
            ? state.previews.map((preview) => {
                if (preview.input === action.payload.input) {
                  return {
                    ...preview,
                    file: action.payload.file,
                    files: preview.fakeData
                      ? [
                          ...preview.files.filter((item) => item.url !== ''),
                          ...action.payload.files,
                        ]
                      : [
                          ...preview.files,
                          ...action.payload.files.filter(
                            (item) => item.url !== ''
                          ),
                        ],
                  };
                }
                return preview;
              })
            : [...state.previews, action.payload],
      };

    case REMOVE_DOCUMENT_PREVIEW:
      return {
        ...state,
        previews: [
          state.previews.filter(
            (item) => item.fileId !== action.payload.fileId
          ),
        ],
      };

    case REMOVE_MULTIPLE_DOCUMENT_PREVIEW:
      return {
        ...state,
        previews: state.previews.find(
          (preview) => preview.input === action.payload.inputName
        )
          ? [
              ...state.previews,
              ...state.previews.find((preview) => {
                if (preview.input === action.payload.inputName) {
                  preview.files = preview.files.filter(
                    (
                      item // eslint-disable-line
                    ) => item.id !== action.payload.fileId
                  );
                }
                return preview;
              }),
            ]
          : state.previews,
      };

    case SET_VIDEO_PREVIEW:
      return {
        ...state,
        videoPreviews: [...state.videoPreviews, action.payload],
      };

    case REMOVE_VIDEO_PREVIEW:
      return {
        ...state,
        videoPreviews: state.videoPreviews.filter(
          (item) => item.fileId !== action.payload.fileId
        ),
      };

    default:
      return state;
  }
};

export default document;
