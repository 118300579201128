const restTypes = (resource) => ({
  fetchAllStart: `${resource.toUpperCase()}_FETCH_ALL_START`,
  fetchAllFinished: `${resource.toUpperCase()}_FETCH_ALL_FINISHED`,

  fetchOneStart: `${resource.toUpperCase()}_FETCH_ONE_START`,
  fetchOneFinished: `${resource.toUpperCase()}_FETCH_ONE_FINISHED`,

  updateStart: `${resource.toUpperCase()}_UPDATE_START`,
  updateFinished: `${resource.toUpperCase()}_UPDATE_FINISHED`,

  deleteStart: `${resource.toUpperCase()}_DELETE_START`,
  deleteFinished: `${resource.toUpperCase()}_DELETE_FINISHED`,

  createStart: `${resource.toUpperCase()}_CREATE_START`,
  createFinished: `${resource.toUpperCase()}_CREATE_FINISHED`,

  requestError: `${resource.toUpperCase()}_REQUEST_ERROR`,

  fetchOrganisationClaimsStart: `${resource.toUpperCase()}_FETCH_CLAIMS_START`,
  fetchOrganisationClaimsFinished: `${resource.toUpperCase()}_FETCH_CLAIMS_FINISHED`,

  fetchPeopleClaimsStart: `${resource.toUpperCase()}_FETCH_CLAIMS_START`,
  fetchPeopleClaimsFinished: `${resource.toUpperCase()}_FETCH_CLAIMS_FINISHED`,
});

export default restTypes;
